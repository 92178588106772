import { listVFOrderStatuses } from '../../../graphql/queries';
import React, { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation  } from 'aws-amplify';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Typography, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Loader } from '@aws-amplify/ui-react';

const PostList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWarehouseCode, setSelectedWarehouseCode] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const today = new Date(); // Get today's date in YYYY-MM-DD format
      const tomorrow = new Date();

      tomorrow.setDate(tomorrow.getDate()+1); // Add 1 day to the current date
      today.setDate(today.getDate());
      
      const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const startOfTomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());

      let items = [];
      let nextToken = null;
      const limit = 40000000; // Fetch data in smaller chunks
      do {
        const response = await API.graphql(
          graphqlOperation(listVFOrderStatuses, {
            limit,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: startOfToday, // Filter for dates greater than yesterday's date
                lt: startOfTomorrow, // Filter for dates less than tomorrow's date
              },
            },
          })
        );
        const fetchedItems = response.data.listVFOrderStatuses.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listVFOrderStatuses.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
      try {
        fetchData();
      } catch (error) {
        console.error('Error fetching user group information:', error);
      }
  }, []);

  const sortedData = useMemo(() => {
    const currentDate = new Date();
  
    // Calculate yesterday's date
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
  
    const filteredItems = data.filter(item =>
      new Date(item.expected_ship_date) >= yesterday &&
      (selectedWarehouseCode === '' || item.warehouse_code === selectedWarehouseCode)
    );
  
    // Group the data by warehouse_code, expected_ship_date, and status
    const groupedData = filteredItems.reduce((acc, item) => {
      const key = `${item.warehouse_code}-${item.expected_ship_date}-${item.status}`;
      if (!acc[key]) {
        acc[key] = {
          id: key,
          warehouse_code: item.warehouse_code,
          expected_ship_date: item.expected_ship_date,
          status: item.status,
          totalQuantity: 0,
        };
      }
      acc[key].totalQuantity += item.quantity;
      return acc;
    }, {});
  
    // Convert the grouped data to an array and sort it
    const sortedItems = Object.values(groupedData).sort((a, b) => {
      const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return b.totalQuantity - a.totalQuantity;
    });
  
    // Calculate the total quantity
    const totalQuantity = sortedItems.reduce((sum, item) => sum + item.totalQuantity, 0);
  
    return sortedItems;
  }, [data, selectedWarehouseCode]);

  const sortedData_table = useMemo(() => {
  const currentDate = new Date();

  // Calculate yesterday's date
  const yesterday = new Date();
  yesterday.setDate(currentDate.getDate() - 1);

  const filteredItems = data.filter(item =>
    new Date(item.expected_ship_date) >= yesterday &&
    (selectedWarehouseCode === '' || item.warehouse_code === selectedWarehouseCode)
  );

  // Group the data by expected_ship_date and warehouse_code
  const groupedData_2 = filteredItems.reduce((acc, item) => {
    const key = `${item.expected_ship_date}-${item.warehouse_code}`;
    if (!acc[key]) {
      acc[key] = {
        id: key,
        expected_ship_date: item.expected_ship_date,
        warehouse_code: item.warehouse_code,
        confirmed: 0,
        new: 0,
        packed: 0,
        'invoice_submitted': 0,
        processed: 0,
        totalOrders: 0,
        totalOrders_processed: 0,
      };
    }

    // Determine the most recent status and update the corresponding column
    if (item.status === 'INVOICE_SUBMITTED') {
      acc[key]['invoice_submitted'] += item.quantity;
      acc[key].totalOrders_processed += item.quantity;
    } else if (item.status === 'PACKED') {
      acc[key].packed += item.quantity;
      acc[key].totalOrders_processed += item.quantity;
    } else if (item.status === 'CONFIRMED') {
      acc[key].confirmed += item.quantity;
    } else if (item.status === 'NEW') {
      acc[key].new += item.quantity;
    }

    acc[key].totalOrders += item.quantity;

    return acc;
  }, {});

  // Convert the grouped data to an array and sort it
  const sortedItems2 = Object.values(groupedData_2).sort((a, b) => {
    const dateComparison = new Date(b.expected_ship_date) - new Date(a.expected_ship_date);
    if (dateComparison !== 0) {
      return dateComparison;
    }
    return b.totalOrders_processed - a.totalOrders_processed;
  });

  // Calculate the processed percentage for each group
  sortedItems2.forEach(item => {
    item.processed = item.totalOrders > 0 ? item.totalOrders_processed / item.totalOrders : 0;
  });

  return sortedItems2;
}, [data, selectedWarehouseCode]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      spacing={2}
    >
      <div>
        <GridToolbarQuickFilter debounceMs={500} />
        <GridToolbarFilterButton />
      </div>
      <div>
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel id="warehouse-code-select-label">Warehouse</InputLabel>
          <Select
            labelId="warehouse-code-select-label"
            id="warehouse-code-select"
            value={selectedWarehouseCode}
            onChange={(event) => setSelectedWarehouseCode(event.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {Array.from(new Set(sortedData_table.map(item => item.warehouse_code))).map((code) => (
              <MenuItem key={code} value={code}>{code}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  );

  // Create the bar chart data
  const barChartData = sortedData.filter(item => (selectedWarehouseCode === '' || item.warehouse_code === selectedWarehouseCode)).reduce((acc, item) => {
    const statusIndex = acc.findIndex(bar => bar.status === item.status);
    if (statusIndex === -1) {
      acc.push({
        status: item.status,
        quantity: item.totalQuantity,
      });
    } else {
      acc[statusIndex].quantity += item.totalQuantity;
    }
    return acc;
  }, []);

  // Calculate the processed and not processed orders
  const filteredSortedData = sortedData.filter(item => (selectedWarehouseCode === '' || item.warehouse_code === selectedWarehouseCode));
  const processedOrders = filteredSortedData.filter(item => item.status === 'INVOICE_SUBMITTED' || item.status === 'PACKED').reduce((sum, item) => sum + item.totalQuantity, 0);
  const notProcessedOrders = filteredSortedData.filter(item => item.status === 'CONFIRMED' || item.status === 'NEW').reduce((sum, item) => sum + item.totalQuantity, 0);
  const totalOrders = processedOrders + notProcessedOrders;

  const pieChartData = [
    { name: 'Processed', value: processedOrders },
    { name: 'Not Processed', value: notProcessedOrders },
  ];

  const COLORS = ['#0859A0', '#FF9900'];
  

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={6} md={6}>
        </Grid>
        <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={fetchData}>
            Refresh Data
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        
        <Grid item lg={5} xs={12} md={5}>
        <Typography
            sx={{
              marginTop: '2vh',
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Processing Status
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item lg={7} xs={12} md={7}>
        <Typography
            sx={{
              marginTop: '2vh',
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Processing Stages
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={barChartData}>
              <XAxis dataKey="status" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Bar dataKey="quantity" fill="#868D95" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

      </Grid>

      <Grid item lg={8} xs={12} md={8}>
      <Typography
            sx={{
              marginTop: '2vh',
              //marginLeft: center,
              align: 'center',
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Node Level Overview
            {loading && <Loader size="large" sx={{ marginLeft: '40px' }} />}
          </Typography>
          <DataGrid
            sx={{
              marginTop: '2vh',
              marginLeft: 1,
              marginRight: 2,
              padding: '10px 18px 18px 18px',
              backgroundColor: "#FFFFFF",
              border: '1px solid #e0e0e0',
              borderRadius: '10px',
              minHeight: '40vh',
              maxHeight: '90vh',
              overflow: 'auto',
            }}
            rows={sortedData_table}
            columns={[
              { field: 'warehouse_code', headerName: 'Node', flex: 1 },
              { field: 'expected_ship_date', headerName: 'Expected Ship Date', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'totalOrders', headerName: 'Total Expected', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'new', headerName: 'New', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'confirmed', headerName: 'Confirmed', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'packed', headerName: 'Packed', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'invoice_submitted', headerName: 'Invoice Submitted', flex: 1, align: 'center', headerAlign: 'center' },
              { field: 'processed', headerName: 'Processed %', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%` },
            ]}
            components={{
              Toolbar: CustomToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableColumnSelector
            disableDensitySelector
            initialState={{
              pagination: { pageSize: 100 },
              sortModel: [{ field: 'processed', sort: 'asc' }],
            }}
          />
        </Grid>

      <Typography
        sx={{
          marginTop: '2vh',
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(15px + 0.5vh)',
          fontWeight: 'bold',
        }}
      >

      </Typography>

    </div>
  );
}

export default PostList;