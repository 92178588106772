import React, { useEffect, useRef } from 'react';
import { Layout, Menu, Button } from 'antd';
import { ToolFilled, ProfileFilled, AmazonCircleFilled, MinusCircleTwoTone, HomeFilled, SignalFilled, FundProjectionScreenOutlined, QuestionCircleOutlined, AmazonOutlined, LoadingOutlined, AlertTwoTone, MailFilled, MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Sider } = Layout;

const Sidebar = ({ isOpen, toggle }) => {
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isOpen) {
        toggle();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggle]);


const menuItems = [
  {
    key: 'home',
    icon: <HomeFilled />,
    label: <Link to="/">Home</Link>,
    path: '/',
  },
  {
    key: 'scorecard',
    icon: <SignalFilled />,
    label: 'Scorecard',
    path: '/scorecard',
    disabled: true,
  },
  {
    key: 'peak-mgt',
    icon: <FundProjectionScreenOutlined />,
    label: 'Peak Mgt',
    children: [
      {
        key: 'sfforecast',
        label: <Link to="/sfforecast">SF - Forecast</Link>,
        path: '/sfforecast',
      },
    ],
  },
  {
    key: 'daily-tasks',
    icon: <ProfileFilled />,
    label: 'Daily Tasks',
    children: [
      {
        key: 'seller-flex',
        label: <Link to="/seller-flex">Seller FLex</Link>,
        path: '/seller-flex',
      },
      {
        key: 'easy-ship',
        label: <Link to="/easy-ship">Easy Ship</Link>,
        path: '/easy-ship',
      },
      {
        key: 'pcp',
        label: <Link to="/pcp">PCP</Link>,
        path: '/pcp',
      },
      {
        key: 'upload-file',
        icon: (
          <span style={{ display: 'inline-flex', backgroundColor: 'red', color: 'white', padding: '0.7em 0.4em', borderRadius: '5px', alignItems: 'center' }}>
            New
          </span>
        ),
        label: <Link to="/upload-file">Upload File</Link>,
        path: '/upload-file',
      },
//      {
//        key: 'vf',
//        label: <Link to="/vf">Vendor Flex</Link>,
//        path: '/vf',
//      },
      {
        key: 'sims',
        label: 'SIMs',
        path: '/sims',
      },
   ],
  },
  
  {
    key: 'order-status',
    icon: <AlertTwoTone />,
    label: 'Order Status',
    children: [
      {
        key: 'cpt-mgt',
        label: <Link to="/cpt-mgt">SF - CPT Management</Link>,
        path: '/cpt-mgt',
      },
      {
        key: 'escutoff',
        label: <Link to="/escutoff">ES Cut-Off</Link>,
        path: 'escutoff',
      },
      {
        key: 'vfct',
        icon: (
          <span style={{ display: 'inline-flex', backgroundColor: 'red', color: 'white', padding: '0.7em 0.4em', borderRadius: '5px', alignItems: 'center' }}>
            New
          </span>
        ),
        label: <Link to="/vfct">VF - Control Tower</Link>,
        path: 'vfct',
      },
    ],
  },
  {
    key: 'seller-management',
    icon: <AmazonCircleFilled />,
    label: 'Seller Management',
    target: false,
    children: [
      {
        key: 'sf-seller-data',
        label: <Link to="/seller-data">[SF] Seller Data</Link>,
        path: '/seller-data',
      },
      {
        key: 'es-seller-data',
        label: <Link to="/seller-data-es">[ES] Seller Data</Link>,
        path: '/seller-data-es',
      },
      {
        key: 'performance-mgt',
        label: 'Performance Mgt',
        path: '/performance-mgt',
        disabled: true,
      },
      {
        key: 'capacity',
        label: 'Capacity',
        path: '/capacity',
        disabled: true,
      },
      {
        key: 'returns',
        label: 'Returns',
        path: '/returns',
        disabled: true,
      },
      {
        key: 'operational-visits',
        label: 'Operational Visits',
        path: '/operational-visits',
        disabled: true,
      },
      {
        key: 'voice-of-the-seller',
        label: 'Voice of the Seller',
        path: '/vos',
        disabled: true,
      },
    ],
  },
  {
    key: 'ses-tracker',
    icon: <MailFilled />,
    label: 'SES Tracker',
    children: [
      {
        key: 'seller-flex-sfs',
        label: <Link to="/srs_sf">Seller Flex</Link>,
        path: '/srs_sf',
      },
      {
        key: 'easy-ship-sfs',
        label: <Link to="/srs_es">Easy Ship</Link>,
        path: '/srs_es',
      },
      {
        key: 'pcp-sfs',
        label: <Link to="/srs_pcp">PCP</Link>,
        path: '/srs_pcp',
      },
    ],
  },
  {
    key: 'resources',
    icon: (
      <span style={{ display: 'inline-flex', backgroundColor: 'red', color: 'white', padding: '0.7em 0.4em', borderRadius: '5px', alignItems: 'center' }}>
        New
      </span>
    ),
    label: 'Resources',
    children: [
      {
        key: 'upload-file',
        icon: (
          <span style={{ display: 'inline-flex', backgroundColor: 'red', color: 'white', padding: '0.7em 0.4em', borderRadius: '5px', alignItems: 'center' }}>
            New
          </span>
        ),
        label: <Link to="/upload-file">Upload File</Link>,
        path: '/upload-file',
      },
      {
        key: 'important-queries',
        label: 'Important Queries',
        path: '/queries',
        disabled: true,
      },
      {
        key: 'seller-flex-wiki',
        label: 'Seller Flex Wiki',
        path: '/wiki',
        disabled: true,
      },
      {
        key: 'sop-repository',
        label: 'SOP Repository',
        path: '/sops',
        disabled: true,
      },
    ],
  },
  {
    key: 'help',
    icon: <QuestionCircleOutlined />,
    label: 'Help',
  },
  {
    key: 'signout',
    icon: <MinusCircleTwoTone />,
    label: 'Sign Out',
    path: 'signout',
  },
];


return (
    <>
      <Button
        type="primary"
        icon={<MenuOutlined />}
        onClick={toggle}
        style={{
          position: 'fixed',
          top: '20px',
          left: '20px',
          zIndex: 1,
        }}
      />
      <Sider
        ref={sidebarRef}
        trigger={null}
        collapsible
        collapsed={!isOpen}
        collapsedWidth={0}
        width={200}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['home']}
          items={menuItems}
        />
      </Sider>
    </>
  );
};

export default Sidebar;