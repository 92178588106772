import { useState, useEffect, useMemo } from "react";
import { listSEStrackSRS } from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid, Avatar } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { groupBy } from 'lodash';
import './summary.css';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as XLSX from 'xlsx';

Amplify.configure(awsconfig);

const groupTypes = ['day', 'week', 'month'];



const Summary = ({ loading, onRefresh }) => {
  
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(true);
      const startDate = new Date('2024-09-01');
      let items = [];
      let nextToken = null;
      do {
        const response = await API.graphql(
          graphqlOperation(listSEStrackSRS, {
            limit: 20000,
            nextToken,
            filter: {
              //send_datetime: {
              //  gt: startDate.toISOString().slice(0, 10),
              //},
              program: {
                eq: 'ES',
              },
            },
          })
        );
        const fetchedItems = response.data.listSEStrackSRS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listSEStrackSRS.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  const handleRefresh = () => {
    fetchData();
  };


  const getWeekNumber = (date_value) => {
    date_value = new Date(date_value);
    date_value.setUTCDate(date_value.getUTCDate() - date_value.getUTCDay() + 6);
    const yearStart = new Date(Date.UTC(date_value.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((date_value - yearStart) / 86400000) / 7);
    return [date_value.getUTCFullYear(), weekNo];
  };

  const getMonthNumber = (date) => {
    date = new Date(date);
    const year = date.getUTCFullYear();
    const monthNo = date.getUTCMonth() + 1;
    return [year, monthNo];
  };


  const aggregatedDataById2AmazonComments2 = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.date_value);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
        case 'month':
          const monthNumber = getMonthNumber(expected_ship_date)[1];
          groupKey = `(${expected_ship_date.getFullYear()}) Month ${monthNumber.toString().padStart(2, '0')}`;
          break;
        
        default:
          return expected_ship_date.toISOString().split('T')[0];
      }
      return `${groupKey}_${item.merchant_id || 'N/A'}_${item.root_cause || 'N/A'}`;
    });

    const aggregatedById2 = [];
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [groupKey, merchant_id, root_cause] = key.split('_');
        const aggregatedItem = { groupKey, merchant_id, root_cause, id: key };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'date_value' && column !== 'merchant_id' && column !== 'root_cause' && (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
        aggregatedById2.push(aggregatedItem);
      }
    }
    return aggregatedById2;
  }, [data, groupByType]);

  const MissColumns = [
    {
      field: "seller_id",
      headerName: "Merchant ID",
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
    {
        field: "seller_name",
        headerName: "Seller Name",
        width: '300',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "owner",
      headerName: "Ops Owner",
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
              <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
            </div>
          );
        }
    
        return null; 
      },
  },
    {
        field: "miss_type",
        headerName: "Miss Type",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
      field: "units",
      headerName: "Units",
      width: '100',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
    field: "miss_date",
    headerName: "Miss Date",
    width: '150',
    editable: false,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'customHeader',
},
    {
        field: "event_type",
        headerName: "Last Event",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "recipient",
        headerName: "Recipient",
        width: '300',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "send_datetime",
        headerName: "Send Datetime",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "delivery_datetime",
        headerName: "Delivery Datetime",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "open_datetime",
        headerName: "Open Datetime",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "srs_click_datetime",
        headerName: "Click SRS Datetime",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "qna_click_datetime",
        headerName: "Click QNA Datetime",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "bounce_subtype",
        headerName: "Rejected Subtype",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "recipient_bounce",
        headerName: "Rejected Recipient",
        width: '300',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "delay_type",
        headerName: "Delivery Delay Type",
        width: '300',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },

    {
        field: "recipient_delay",
        headerName: "Delivery Delay Recipient",
        width: '300',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "srs_url",
        headerName: "URL SRS",
        width: '800',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    }
  ];
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '600px'
  };

  const [downloadUrl, setDownloadUrl] = useState(null);

const handleExportClick = () => {
  const excelData = data.map((row) => row);
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(excelData);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const excelContent = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const downloadUrl = URL.createObjectURL(excelContent);
  setDownloadUrl(downloadUrl);
};

const handleDownload = () => {
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', 'data.xlsx');
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <Grid item lg={6} xs={6} md={6}>
        </Grid>
        <Grid item lg={6} xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleRefresh}>
            Refresh Data
          </Button>
          
        </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Self Report Survey Tracker
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            Table detailing email interactions
          </Typography>
        </Grid>
        <Grid item xs={8} md={8} className="blankReasonsChip" sx={{marginTop: '3vh', textAlign: 'right'}}>
        </Grid>
      </Grid>

      {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh' }}>
                <CircularProgress />
              </Box>
            ) : (
      <DataGrid
        sx={{
          marginTop: '2vh',
          marginLeft: 2,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          maxHeight: '90%',
        }}
        rows={data}
        columns={MissColumns}
        components={{
          Toolbar: () => <CustomToolbar data={data} />,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        editMode="row"
        checkboxSelection
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 15 } },
        }}
      />
      )}
       {downloadUrl ? (
            <a href={downloadUrl} download="data.xlsx" style={{ marginRight: "16px" }}>
              <Button variant="contained" color="primary">
                Export to XLSX
              </Button>
            </a>
          ) : (
            <Button variant="contained" color="primary" onClick={handleExportClick}>
              Export to XLSX
            </Button>
          )}
    </div>
    
    );
};


export default withAuthenticator(Summary);