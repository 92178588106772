import { useState, useEffect, useMemo } from "react";
import { listPCPPreWBRS, listPCPDefects} from "../../../graphql/queries";
import { API, Amplify, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button} from '@mui/material';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';



Amplify.configure(awsconfig);

const groupTypes = ['day','week'];

const Summary = ({ loading, onRefresh }) => {
  const [isLoading, setLoading] = useState(loading);
  const [data, setData] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const [totalConfirmedPackages, setTotalConfirmedPackages] = useState(0);
  const [defectData, setDefectsData] = useState([]); // Estado para armazenar dados de defects

  const fetchData = async () => {
    try {
      setLoading(true);
      let items = [];
      let nextToken = null;
      const startDate = new Date('2024-09-01');
      const today = new Date();
      const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, 1);
  
      do {
        const response = await API.graphql(
          graphqlOperation(listPCPPreWBRS, {
            limit: 20000,
            nextToken,
            filter: {
              expected_ship_date: {
                gt: startDate.toISOString().slice(0, 10),
              },
            },

          })
        );
        const fetchedItems = response.data.listPCPPreWBRS.items;
        items = items.concat(fetchedItems);
        nextToken = response.data.listPCPPreWBRS.nextToken;
      } while (nextToken);
  
      setData(items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);

//inicio defects
const fetchDefectsData = async () => {
  try {
    setLoading(true);
    const startDate = new Date('2024-09-01');
    let items = [];
    let nextToken = null;
    do {
      const response = await API.graphql(graphqlOperation(listPCPDefects, 
        { limit: 20000, 
          nextToken,
          filter: {
            date_value: {
              gt: startDate.toISOString().slice(0, 10),
            },
          },
        })
      );
      
      const fetchedItems = response.data.listPCPDefects.items;
      items = items.concat(fetchedItems);
      nextToken = response.data.listPCPDefects.nextToken;
    } while (nextToken);
    setDefectsData(items);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching defects data:', error);
  }
};

useEffect(() => {
  fetchDefectsData();
}, []);

  //fim defects

  useEffect(() => {
    // Calculate total confirmed packages when data changes
    const total = data.reduce((acc, item) => acc + (item.total || 0), 0);
    setTotalConfirmedPackages(total);
  }, [data]); 

  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };

  const getWeekNumber = (date) => {
    // Copy date so don't modify original
    date = new Date(date);

    // Set to nearest Saturday: current date - current day number + 6
    date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 6);

    // Get first day of year
    var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Saturday
    var weekNo = Math.ceil(((date - yearStart) / 86400000) / 7);

    // Return array of year and week number
    return [date.getUTCFullYear(), weekNo];
  };

  const getMonthNumber = (date) => {
    // Copy date so don't modify original
    date = new Date(date);
  
    // Get the year
    const year = date.getUTCFullYear();
  
    // Get month number (0-11), then add 1 to get (1-12)
    const monthNo = date.getUTCMonth() + 1;
  
    // Return array of year and month number
    return [year, monthNo];
  };

  const getQuarterNumber = (date) => {
    // Copy date so don't modify original
    date = new Date(date);
  
    // Get the year
    const year = date.getUTCFullYear();
  
    // Get month number (0-11), then calculate quarter (1-4)
    const month = date.getUTCMonth();
    const quarterNo = Math.floor(month / 3) + 1;
  
    // Return array of year and quarter number
    return [month, quarterNo];
  };
  

  

  

  const groupDefectData = useMemo(() => {
    const sortedData = [...defectData].sort((a, b) => new Date(b.date_value) - new Date(a.date_value));
    return groupBy(sortedData, (item) => {
      const date_value = new Date(item.date_value);
      switch (groupByType) {

        case 'week':
          const weekNumber = getWeekNumber(date_value)[1];
          return `(${date_value.getFullYear()}) Week ${weekNumber.toString().padStart(2, '0')}`;
        case 'month':
          const monthNumber = getMonthNumber(date_value)[1];
          return `(${date_value.getFullYear()}) Month ${monthNumber.toString().padStart(2, '0')}`;
          case 'quarter':
            const quarterNumber = getQuarterNumber(date_value)[1];
            return `(${date_value.getFullYear()}) Q${quarterNumber.toString().padStart(2, '0')}`;
        case 'year':
          return `(${date_value.getFullYear()})`;
        default:
          return date_value.toISOString().split('T')[0];
      }
    });
  }, [defectData, groupByType]);
  
  const groupData = useMemo(() => {
    // Ordena os dados por 'expected_ship_date' antes de agrupá-los
    const sortedData = [...data].sort((a, b) => new Date(b.expected_ship_date) - new Date(a.expected_ship_date));
  
    return groupBy(sortedData, (item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      switch (groupByType) {
        case 'day':
          return expected_ship_date.toISOString().split('T')[0];
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          return `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
        
      }
    });
  }, [data, groupByType]);
  
  const aggregatedData = useMemo(() => {
    const uniqueData = {};
    let id = 0;
  
    for (const key in groupData) {
      if (groupData.hasOwnProperty(key)) {
        const items = groupData[key];
        uniqueData[key] = {
          id: id++,
          expected_ship_date: key,
          total: 0,
          fdps: 0,
          pickup: 0,
          fdps2: 0,
          seller_miss: 0,
          seller_miss_2: 0,
          seller_miss_1: 0, // Add this new field
          carrier_miss_1: 0,
          carrier_miss_2: 0,
          carrier_miss: 0,
          seller_miss_perc: 0,
          carrier_miss_perc: 0,
        };
  
        for (const item of items) {
          uniqueData[key].total += item.total || 0;
          uniqueData[key].fdps += item.fdps || 0;
          uniqueData[key].pickup += (item.total - item.fdps) || 0;
  
          // Check if the amazon_comments is "Incorrect Address" and add the value to the incorrect_address_sum
          if
          (item.reason.includes("No Show Seller")) {
            uniqueData[key].seller_miss_1 += item.total || 0;
            uniqueData[key].seller_miss_2 += item.fdps || 0;

           }

           if
          (item.reason === "No Show Carrier") {
            uniqueData[key].carrier_miss_1 += item.total || 0;
            uniqueData[key].carrier_miss_2 += item.fdps || 0;
           }

           if
          (item.reason === "No Show Tex") {
            uniqueData[key].carrier_miss_1 += item.total || 0;
            uniqueData[key].carrier_miss_2 += item.fdps || 0;
           }
        }

        uniqueData[key].carrier_miss = uniqueData[key].carrier_miss_1 - uniqueData[key].carrier_miss_2;
        uniqueData[key].seller_miss = uniqueData[key].seller_miss_1 - uniqueData[key].seller_miss_2;

        
  
        if (uniqueData[key].total && uniqueData[key].fdps) {
          uniqueData[key].fdps2 = ((uniqueData[key].total - uniqueData[key].pickup) / uniqueData[key].total * 100).toFixed(2) + '%';
        }

        if (uniqueData[key].total && uniqueData[key].seller_miss) {
          uniqueData[key].seller_miss_perc = ((uniqueData[key].seller_miss) / uniqueData[key].total * 100).toFixed(2) + '%';
        }

        if (uniqueData[key].total && uniqueData[key].carrier_miss) {
          uniqueData[key].carrier_miss_perc = ((uniqueData[key].carrier_miss) / uniqueData[key].total * 100).toFixed(2) + '%';
        }
      }
    }
  
    return Object.values(uniqueData);
  }, [groupData]);

  console.log('aggregatedData:', aggregatedData);


  
  
  const aggregatedDataById2AmazonComments2 = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
      }
      return `${groupKey}_${item.amazon_comments || 'N/A'}`;
    });
    
    

    const aggregatedById2 = [];
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [groupKey, amazon_comments] = key.split('_');
        const aggregatedItem = { groupKey, amazon_comments, id2: key, total2: items.length };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'expected_ship_date' && column !== 'amazon_comments' && column !== 'actual_pick_up_date' &&(typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }

        
        aggregatedById2.push(aggregatedItem);
      }
    }   

    // Sort the aggregatedById2 array by the groupKey in descending order
    aggregatedById2.sort((b, a) => a.groupKey.localeCompare(b.groupKey));
  
    // Filter out rows not related to Amazon comments
    const filteredAggregatedById2 = aggregatedById2.filter(item => (
      item.amazon_comments &&
      (item.amazon_comments.includes("Seller didn't provide the packages") ||
       item.amazon_comments.includes('Seller Unavailable') ||
       item.amazon_comments.includes('Incorrect Address') ||
       item.amazon_comments.includes('- Seller'))
    ));

    return filteredAggregatedById2;
  }, [data, groupByType]);

  const aggregatedDefectData = useMemo(() => {
    const aggregated = [];
    for (const key in groupDefectData) {
      if (groupDefectData.hasOwnProperty(key)) {
        const items = groupDefectData[key];
        const aggregatedItem = { id: key, date_value: key};
        for (const item of items) {
          for (const column in item) {
            if (column !== 'date_value' && (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
        if (aggregatedItem.hdsc && aggregatedItem.shipped_units) {
          aggregatedItem.defhdsc = (aggregatedItem.hdsc / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defhdsc = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.rpi && aggregatedItem.shipped_units) {
          aggregatedItem.defrpi = (aggregatedItem.rpi / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defrpi = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.prep && aggregatedItem.shipped_units) {
          aggregatedItem.defprep = (aggregatedItem.prep / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defprep = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.iss_not_inbounded && aggregatedItem.shipped_units) {
          aggregatedItem.defiss_not_inbounded = (aggregatedItem.iss_not_inbounded / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defiss_not_inbounded = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        if (aggregatedItem.iss_inbounded && aggregatedItem.shipped_units) {
          aggregatedItem.defiss_inbounded = (aggregatedItem.iss_inbounded / aggregatedItem.shipped_units * 100).toFixed(2) + '%'
        } else {
          aggregatedItem.defiss_inbounded = 0; // Caso miss ou total_units sejam zero ou indefinidos
        }

        
        aggregated.push(aggregatedItem);
      }
    }
    return aggregated;
  }, [groupDefectData]);

  const calculateCancelledPackages = (totalConfirmed, totalFdps) => {
    if (totalConfirmed - totalFdps === 0) return 0;
    return (totalConfirmed - totalFdps);
  };

  const aggregatedDataById2AmazonComments = useMemo(() => {
    const groupedById2 = groupBy(data, (item) => {
      const expected_ship_date = new Date(item.expected_ship_date);
      let groupKey;
      switch (groupByType) {
        case 'day':
          groupKey = expected_ship_date.toISOString().split('T')[0];
          break;
        case 'week':
          const weekNumber = getWeekNumber(expected_ship_date)[1];
          groupKey = `${expected_ship_date.getFullYear()} Week ${weekNumber.toString().padStart(2, '0')}`;
          break;
      }
      return `${groupKey}_${item.amazon_comments || 'N/A'}`;
    });
  
    const aggregatedById3 = [];
    for (const key in groupedById2) {
      if (groupedById2.hasOwnProperty(key)) {
        const items = groupedById2[key];
        const [groupKey, amazon_comments] = key.split('_');
        const aggregatedItem = { groupKey, amazon_comments, id2: key, total2: items.length };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'expected_ship_date' && column !== 'amazon_comments' && column!== 'actual_pick_up_date'&&(typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
       
        aggregatedById3.push(aggregatedItem);
      }
    }
    // Sort the aggregatedById2 array by the groupKey in descending order
    aggregatedById3.sort((b, a) => a.groupKey.localeCompare(b.groupKey));
  
    // Filter out rows not related to Amazon comments
    const filteredAggregatedById3 = aggregatedById3.filter(item => (
      item.amazon_comments &&
      (item.amazon_comments.includes("Bip at Carrier's Facility") ||
        item.amazon_comments.includes('Carrier No Show') ||
        item.amazon_comments === 'Others - Carrier')
    ));

    return filteredAggregatedById3;
  }, [data, groupByType]);


  const sellerMissColumns = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'amazon_comments', type: 'number', headerName: 'Reasons', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'cancelledPackages', type: 'number', headerName: 'Packages', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'cancelledPackagesPercentage', type: 'number', headerName: 'Packages %', flex: 1, align: 'center', headerAlign: 'center', valueGetter: (params) => {
      const matchingRow = aggregatedData.find(item => item.expected_ship_date === params.row.groupKey);
      if (matchingRow) {
        console.log("foi");
        const totalConfirmed = matchingRow[MissColumns.find(column => column.field === 'total').field];
        const cancelledPackages = calculateCancelledPackages(
          params.row[MissColumns.find(column => column.field === 'total').field],
          params.row[MissColumns.find(column => column.field === 'fdps').field]
        );
        return ((cancelledPackages / totalConfirmed) * 100).toFixed(2) + '%';
      } else {
        return 'N/A';
      }
    } },
  ];

  const carrierMissColumns = [
    { field: 'groupKey', headerName: 'Group Key', width: 150 },
    { field: 'amazon_comments', type: 'number', headerName: 'Reasons', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'cancelledPackages', type: 'number', headerName: 'Packages', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'cancelledPackagesPercentage', type: 'number', headerName: 'Packages %', flex: 1, align: 'center', headerAlign: 'center', valueGetter: (params) => {
      const matchingRow = aggregatedData.find(item => item.expected_ship_date === params.row.groupKey);
      if (matchingRow) {
        console.log("foi");
        const totalConfirmed = matchingRow[MissColumns.find(column => column.field === 'total').field];
        const cancelledPackages = calculateCancelledPackages(
          params.row[MissColumns.find(column => column.field === 'total').field],
          params.row[MissColumns.find(column => column.field === 'fdps').field]
        );
        return ((cancelledPackages / totalConfirmed) * 100).toFixed(2) + '%';
      } else {
        return 'N/A';
      }
    } },
  ];

  const MissColumns = Array.from(new Set([
    { field: 'expected_ship_date', headerName: 'Expected Date', flex: 1, },
    { field: 'total', type: 'number' , headerName: 'Confirmed Packages', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'fdps', type: 'number' , headerName: 'Picked Packages', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'pickup', type: 'number' , headerName: 'Pickup Misses', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'fdps2', type: 'number' , headerName: 'FDPS %', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'seller_miss', type: 'number' , headerName: 'Seller Miss', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'seller_miss_perc', type: 'number' , headerName: 'Seller Miss %', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'carrier_miss', type: 'number' , headerName: 'Carrier Miss', flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'carrier_miss_perc', type: 'number' , headerName: 'Carrier Miss %', flex: 1, align: 'center', headerAlign: 'center', },
    
  ]));



  const defectstable = [
    { field: 'date_value', headerName: 'Date',width: 150 },
    { field: 'hdsc', type: 'number', headerName: 'HDSC', align: 'center', headerAlign: 'center', flex: 1 },
    { field: 'defhdsc', type: 'number', headerName: 'HDSC %', align: 'center', headerAlign: 'center', flex: 1 },
    { field: 'rpi', type: 'number', headerName: 'RPI', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'defrpi', type: 'number', headerName: 'RPI %', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'iss_inbounded', type: 'number', headerName: 'ISS Inbounded', align: 'center', headerAlign: 'center',width: 150 },
    { field: 'defiss_inbounded', type: 'number', headerName: 'ISS Inbounded %',  align: 'center', headerAlign: 'center',width: 150 },
    { field: 'iss_not_inbounded', type: 'number', headerName: 'ISS not Inbounded',  align: 'center', headerAlign: 'center',width: 150 },
    { field: 'defiss_not_inbounded', type: 'number', headerName: 'ISS not Inbounded %',  align: 'center', headerAlign: 'center',  width: 150 },
    { field: 'prep', type: 'number', headerName: 'Defect Prep', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'defprep', type: 'number', headerName: 'Prep %', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'shipped_units', type: 'number', headerName: 'Inbounded Units', align: 'center', headerAlign: 'center',width: 150 },
  ];
  
  const tableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginTop: '20px'
  };

  const tableStyle = {
    flex: 1,
    height: '400px'
  };

  

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', justifyContent: 'center', }}>
        {groupTypes.map((type) => (
          <Button key={type} variant={groupByType === type ? 'contained' : 'outlined'} onClick={() => handleGroupByChange(type)}>
            {type}
          </Button>
        ))}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        

        <><div style={{ ...tableContainerStyle, marginBottom: '40px' }}>
            <div style={tableStyle}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>General</Typography>
              <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
            }}
                rows={aggregatedData}
                columns={MissColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer>
                      <GridToolbarExport />
                      <GridToolbarQuickFilter />
                    </GridToolbarContainer>
                  )
                }} />
            </div>
           
          </div>
          
          <div style={{ ...tableContainerStyle, marginTop: '80px' }}>
              <div style={tableStyle}>
                <Typography variant="h6">Seller Miss</Typography>
                <DataGrid
                sx={{
                  marginTop: '2vh',
                  marginLeft: 2,
                  marginRight: 2,
                  padding: '10px 18px 18px 18px',
                  backgroundColor: "#FFFFFF",
                  border: '0px',
                  borderRadius: '10px',
                  minHeight: '40vh',
                  maxHeight: '90vh',
                  overflow: 'auto', // Enable vertical scrolling if needed
              }}
                  
                  rows={aggregatedDataById2AmazonComments2.map(row => ({
                    ...row,
                    cancelledPackages: calculateCancelledPackages(row[MissColumns.find(column => column.field === 'total').field],row[MissColumns.find(column => column.field === 'fdps').field]),
                  }))}



                  columns={sellerMissColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                        <GridToolbarQuickFilter />
                      </GridToolbarContainer>
                    )
                  }} />
              </div>
              <div style={tableStyle}>
                <Typography variant="h6">Carrier Miss</Typography>
                <DataGrid
                sx={{
                  marginTop: '2vh',
                  marginLeft: 2,
                  marginRight: 2,
                  padding: '10px 18px 18px 18px',
                  backgroundColor: "#FFFFFF",
                  border: '0px',
                  borderRadius: '10px',
                  minHeight: '40vh',
                  maxHeight: '90vh',
                  overflow: 'auto', // Enable vertical scrolling if needed
              }}
                rows={aggregatedDataById2AmazonComments.map(row => ({
                  ...row,
                  cancelledPackages: calculateCancelledPackages(row[MissColumns.find(column => column.field === 'total').field],row[MissColumns.find(column => column.field === 'fdps').field]),
                }))}
                  columns={carrierMissColumns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  components={{
                    Toolbar: () => (
                      <GridToolbarContainer>
                        <GridToolbarExport />
                        <GridToolbarQuickFilter />
                      </GridToolbarContainer>
                    )
                  }} />
              </div>
          </div>
          <div style={{ ...tableContainerStyle, marginTop: '80px' }}>
          <div style={tableStyle}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>Defects</Typography>
              <DataGrid
              sx={{
                marginTop: '2vh',
                marginLeft: 2,
                marginRight: 2,
                padding: '10px 18px 18px 18px',
                backgroundColor: "#FFFFFF",
                border: '0px',
                borderRadius: '10px',
                minHeight: '40vh',
                maxHeight: '90vh',
                overflow: 'auto', // Enable vertical scrolling if needed
            }}
                rows={aggregatedDefectData}
                columns={defectstable}
                components={{
                  Toolbar: () => (
                    <GridToolbarContainer>
                      <GridToolbarExport />
                      <GridToolbarQuickFilter />
                    </GridToolbarContainer>
                  )
                }}
                pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
                />
            </div>
           
          </div>
          
          
          
          </>

          
        
      )
      }

    </div>
  );
};

export default withAuthenticator(Summary);
